<template>
    <div class="tabsview">
        <v-tabs
            color="primary"
            :value="tabindex"
            :key="JSON.stringify(tabs)"
            prev-icon="mdi-delete-outline"
        >
            <v-tab
                class="px-0 mr-4"
                v-for="(tab, index) in tabs"
                :key="`${index}`"
                :disabled="tab.disabled"
                @change="$emit('change', tab.name)"
            >
                {{ tab.label }}

                <v-chip
                    small
                    v-if="tab.counter"
                    :color="color(tab)"
                    class="ml-2 justify-center"
                    :class="textColorClass(tab)"
                    :outlined="outlined(tab)"
                >
                    {{ tab.counter }}</v-chip
                >
            </v-tab>
        </v-tabs>
        <v-tabs-items
            :value="tabindex"
            @change="$emit('change', tabs_list[$event])"
            touchless
        >
            <slot name="tabs"> </slot>
        </v-tabs-items>
    </div>
</template>

<script>
export default {
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            required: false,
        },
    },
    data() {
        return { tabs_list: this.tabs.map((item) => item.name) };
    },
    computed: {
        tabindex() {
            return this.tabs_list.indexOf(this.value);
        },
    },
    methods: {
        active(tab) {
            return tab.name == this.value;
        },
        textColorClass(tab) {
            if (!tab.counter_dim) {
                return "white--text";
            }
            return "grey--text";
        },
        color(tab) {
            if (tab.counter_color) {
                return tab.counter_color;
            }
            if (tab.counter_dim && !this.active(tab)) {
                return "grey";
            }
            return "primary";
        },
        outlined(tab) {
            if (tab.counter_dim) {
                return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss">
.tabsview {
    .v-tab {
        text-transform: initial !important;
    }
    .v-slide-group__prev {
        display: none !important;
    }
}
</style>
