<template>
    <div class="d-flex align-center providername" v-if="provider">
        <lookup v-if="lookup" object="provider" :params="lookup">
            {{ provider.name }}
        </lookup>
        <router-link
            v-else-if="routeExists('provider')"
            :to="{
                name: 'provider',
                params: {
                    provider_id: provider.id,
                },
            }"
        >
            <Stroke :strike="provider.archived">
                {{ provider.name }}
            </Stroke>
        </router-link>
        <span v-else>{{ provider.name }}</span>
        <v-tooltip top v-if="routeExists('provider')">
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <v-icon
                        v-if="provider.entity"
                        color="primary"
                        size="20"
                        class="ml-1 icon"
                        >mdi-link</v-icon
                    >
                </div>
            </template>
            {{ $translate("providerIsConnected") }}
        </v-tooltip>
    </div>
</template>

<script>
import GlobalMixins from "@/mixins/global";

export default {
    mixins: [GlobalMixins],
    props: {
        provider: {
            required: true,
            type: Object,
        },
        lookup: {
            required: false,
            type: Object,
        },
    },
    i18n: {
        messages: {
            en: {
                providerIsConnected: "This provider is connected",
            },
            fr: {
                providerIsConnected: "Ce fournisseur est connecté",
            },
        },
    },
};
</script>

<style scoped>
.providername .icon {
    /* opacity: 0;
    transition: opacity 0.3s; */
}
.providername:hover .icon {
    opacity: 1;
}
</style>
