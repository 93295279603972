import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    customer: false,
    customer_saving: false,
    customer_saving_errors: {},
};


const mutations = {

    async updateCustomer(state, customer) {
        state.customer = customer;
    },

    updateCustomerSaving(state, saving) {
        state.customer_saving = saving;
        state.customer_saving_errors = {};
    },

    updateCustomerSavingErrors(state, saving_errors) {
        state.customer_saving_errors = saving_errors || {};
        state.customer_saving = false;
    }
};

const actions = {

    async saveCustomer({ commit, dispatch, state }, params) {
        commit('updateCustomerSaving', true);

        let customer = { ...state.customer };
        try {
            const response = await axios.patch(`/api/customers/${state.customer.id}/`, customer);
            commit('updateCustomer', { ...state.customer, id: response.data.id });
            commit('updateCustomerSaving', false);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateCustomerSavingErrors', error.details);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) {
        let customer = {
            ...params.customer
        };
        if (!customer.billing_address) {
            customer.billing_address = {};
        }
        commit('updateCustomer', customer);
    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
