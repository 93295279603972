import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import allocations from './submodules/allocations';
import orderitems from "./submodules/orderitems";
import shipmentitems from "./submodules/shipmentitems";
import receiptitems from "./submodules/receiptitems";
import providers from "./submodules/providers";

const state = {
    product: null,
    product_loading: false,
    product_loading_errors: null,
    product_deleting: false,

    product_saving: false,
    product_saving_errors: {},

    producttypes: [],
    producttypes_loading: false,
    producttypes_loading_errors: null,

    evolution: null,
    evolutionPeriod: null,
    evolutionLoading: false,
    evolutionLoadingErrors: {},

    movements: [],
    movements_filters: { limit: 20 },
    movements_count: 0,
    movements_loading: false,
    movements_loaded: false,
    movements_loading_errors: null,

    productpictures: [],
    productpictures_count: 0,
    productpictures_loading: false,
    productpictures_loading_errors: null,

    productpicture_saving: false,
    productpicture_saving_errors: null,

    productproviders: [],
    productproviders_count: 0,
    productproviders_filters: { limit: 20 },
    productproviders_loading: false,
    productproviders_loading_errors: null,
    productproviders_saving: false,
    productproviders_saving_errors: null,

    stockproducts: [],
    stockproducts_fetching_errors: null,

    section: null,

    product_stats: {},
    product_stats_loading: false,
    product_stats_loading_errors: null,
};

let getMovementsFilters = function (product, filters) {
    return { product: product.id };
};


const mutations = {

    updateProduct(state, product) {
        state.product_saving = false;
        state.product_saving_errors = {};

        if (product && state.product && product.id && state.product.id) {
            state.product = product;
            return;
        }
        state.product = product;
        state.product_stats = {};

        state.movements = [];
        state.movements_count = 0;
        state.movements_loaded = false;

        state.productpictures = [];
        state.productpictures_count = 0;

        state.stockproducts = [];
        state.movements_filters = { ordering: "-creation_date", limit: state.movements_filters.limit, offset: 0 };
    },

    updateSection(state, section) {
        state.section = section;
    },

    updateProductLoading(state, loading) {
        state.product_loading = loading;
    },
    updateProductLoadingErrors(state, errors) {
        state.product_loading_errors = errors;
    },

    updateProductSavingErrors(state, errors) {
        state.product_saving = false;
        state.product_saving_errors = errors;
    },
    updateProductSaving(state, saving) {
        state.product_saving = saving;
    },
    updateProductTypes(state, producttypes) {
        state.producttypes = producttypes;
    },
    updateProductTypesLoading(state, loading) {
        state.producttypes_loading = loading;
    },
    updateProductTypesLoadingErrors(state, errors) {
        state.producttypes_loading_errors = errors;
    },

    updateProductDeleting(state, deleting) {
        state.product_deleting = deleting;
    },

    updateMovements(state, data) {
        state.movements = data.results;
        state.movements_count = data.count;
        state.movements_loaded = true;
    },
    updateMovementsLoading(state, loading) {
        state.movements_loading = loading;
    },
    updateMovementsLoadingErrors(state, errors) {
        state.movements_loading_errors = errors;
    },
    updateMovementsFilters(state, filters) {
        state.movements_filters = filters;
    },

    updateEvolution(state, evolution) {
        state.evolution = {
            labels: evolution.labels.map((label) => new Date(label)),
            datasets: evolution.datasets,
        }
        state.evolutionLoading = false;
        state.evolutionLoadingErrors = {};
    },
    updateEvolutionPeriod(state, evolutionPeriod) {
        state.evolution = null;
        state.evolutionPeriod = evolutionPeriod;
    },
    updateEvolutionLoading(state, evolutionLoading) {
        state.evolutionLoading = evolutionLoading;
    },
    updateEvolutionLoadingErrors(state, evolutionLoadingErrors) {
        state.evolutionLoading = false;
        state.evolutionLoadingErrors = evolutionLoadingErrors;
    },

    updateProductProviders(state, productproviders) {
        state.productproviders = productproviders;
        state.productproviders_saving_errors = null;
    },
    updateProductProvidersFilters(state, filters) {
        state.productproviders_filters = filters;
    },
    updateProductProvidersCount(state, count) {
        state.productproviders_count = count;
    },
    updateProductProvidersLoading(state, loading) {
        state.productproviders_loading = loading;
    },
    updateProductProvidersLoadingErrors(state, errors) {
        state.productproviders_loading_errors = errors;
    },
    updateProductProvidersSavingErrors(state, errors) {
        state.productproviders_saving = false;
        state.productproviders_saving_errors = errors;
    },
    updateProductProvidersSaving(state, saving) {
        state.productproviders_saving = saving;
    },

    updateProductPictures(state, productpictures) {
        state.productpictures = productpictures;
    },
    updateProductPicturesCount(state, count) {
        state.productpictures_count = count;
    },
    updateProductPicturesLoading(state, loading) {
        state.productpictures_loading = loading;
    },
    updateProductPicturesLoadingErrors(state, errors) {
        state.productpictures_loading_errors = errors;
    },

    updateProductPictureSavingErrors(state, errors) {
        state.productpicture_saving_errors = errors;
    },
    updateProductPictureSaving(state, saving) {
        state.productpicture_saving = saving;
    },

    updateStockProducts(state, stockproducts) {
        state.stockproducts = stockproducts;
        state.stockproducts_fetching_errors = null;
    },
    updateStockProductsFetchingErrors(state, errors) {
        state.stockproducts_fetching_errors = errors;
    },

    updateProductStats(state, stats) {
        state.product_stats = stats;
    },
    updateProductStatsLoading(state, loading) {
        state.product_stats_loading = loading;
    },
    updateProductStatsLoadingErrors(state, errors) {
        state.product_stats_loading_errors = errors;
    },

};

const actions = {

    async fetchProductStats({ commit, dispatch, state }, params) {
        commit('updateProductStatsLoading', true);
        try {
            const response = await axios.get(`/api/products/${state.product.id}/stats/`);
            commit('updateProductStats', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductStatsLoading', false);
        }
    },

    async fetchEvolution({ commit, dispatch, state }, params) {
        commit('updateEvolutionLoading', true);
        try {
            const response = await axios.get('/api/stats/stock/byproduct/', { params: { start: state.evolutionPeriod?.start, end: state.evolutionPeriod?.end, product: state.product.id } });
            commit('updateEvolution', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateEvolutionLoadingErrors', error.details);
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateEvolutionLoading', false);
        }
    },


    async fetchProduct({ commit, dispatch, state }, params) {
        commit('updateProductLoading', true);
        let url = null;
        if (params && params.product_id) {
            url = `/api/products/${params.product_id}/`;
        } else if (state.product) {
            url = `/api/products/${state.product.id}/`;
        } else {
            throw "no product to fetch";
        }
        try {
            const response = await axios.get(url);
            commit('updateProduct', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductLoading', false);
        }
    },

    async saveProduct({ commit, dispatch, state }, params) {
        let url = '/api/products/';
        let method = axios.put;

        if (state.product?.id) {
            url = `/api/products/${state.product.id}/`;
        } else {
            url = '/api/products/';
            method = axios.post;
        }

        try {
            const response = await method(url, state.product);
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async patchProduct({ commit }, params) {
        commit('updateProductSaving', true);
        try {
            const url = `/api/products/${params.instance.id}/`;
            const response = await axios.patch(url, params.instance);
            commit('updateProduct', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductSavingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductSaving', false);
        }
    },


    async deleteProduct({ commit, dispatch, state }, params) {
        commit('updateProductDeleting', true);

        const url = `/api/products/${params.instance.id}/`;
        try {
            const response = await axios.delete(url);
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateProductDeleting', false);
        }
    },


    async unarchiveProduct({ commit, dispatch, state }, params) {

        try {
            await axios.post(`/api/products/${state.product.id}/unarchive/`);
        } finally {
            dispatch("fetchProduct");
        }
    },


    async fetchMovements({ commit, dispatch, state }, params) {
        if (!state.product) {
            return;
        }
        commit('updateMovementsLoading', true);
        commit('updateMovementsLoadingErrors', null);

        try {
            const response = await axios.get('/api/movements/', { params: Object.assign({}, state.movements_filters, getMovementsFilters(state.product)) });
            commit('updateMovements', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateMovementsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateMovementsLoading', false);
        }
    },

    async fetchProductPictures({ commit, dispatch, state }, params) {
        commit('updateProductPicturesLoading', true);
        commit('updateProductPicturesLoadingErrors', null);

        try {
            const response = await axios.get('/api/productpictures/', { params: { limit: 100, product: state.product.id } });
            commit('updateProductPictures', response.data.results);
            commit('updateProductPicturesCount', response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateProductPicturesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateProductPicturesLoading', false);
        }
    },


    fetchProductProviders({ commit, dispatch, state }, params) {
        commit('updateProductProvidersLoading', true);
        commit('updateProductProvidersLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get(`/api/products/${state.product.id}/providers/`, { params: state.productproviders_filters })
                .then((response) => {
                    commit('updateProductProviders', response.data.results);
                    commit('updateProductProvidersCount', response.data.count);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductProvidersLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProductProvidersLoading', false);
                })
        });
    },

    fetchProductTypes({ commit, dispatch, state }, params) {
        commit('updateProductTypesLoading', true);
        commit('updateProductTypesLoadingErrors', null);

        return new Promise((resolve, reject) => {
            axios.get('/api/producttypes/', { params: { limit: 100 } })
                .then((response) => {
                    commit('updateProductTypes', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypesLoadingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProductTypesLoading', false);
                })
        });
    },


    saveProductPicture({ commit, dispatch, state }, params) {
        if (!params || !params.instance) {
            return;
        }

        let headers = {};
        let data = params.instance;
        let url = '/api/productpictures/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/productpictures/${params.instance.id}/`;
            method = params.patch ? axios.patch : axios.put;
        } else {
            headers = { 'Content-Type': 'multipart/form-data' };
            data = new FormData();
            for (let key in params.instance) {
                if (params.instance[key]) {
                    data.append(key, params.instance[key]);
                }
            }
        }

        commit('updateProductPictureSavingErrors', null);
        commit('updateProductPictureSaving', true);

        return new Promise((resolve, reject) => {
            method(url, data, { headers })
                .then((response) => {
                    resolve({ productpicture: response.data });
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductPictureSavingErrors', error.details);
                    reject(error);
                }).finally(() => {
                    commit('updateProductPictureSaving', false);
                })
        });
    },


    deleteProductPicture({ commit, dispatch, state }, params) {
        const url = `/api/productpictures/${params.instance.id}/`;

        return new Promise((resolve, reject) => {
            axios.delete(url)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    fetchStockProducts({ commit, dispatch, state }, params) {
        return new Promise((resolve, reject) => {

            axios.get('/api/stockproducts/', { params: { product: state.product.id } })
                .then((response) => {
                    commit('updateStockProducts', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateStockProductsFetchingErrors', error.details);
                    reject(error);
                });
        });
    },

    setEvolutionPeriod({ commit, dispatch, state }, params) {
        commit('updateEvolutionPeriod', params);
        dispatch('fetchEvolution');
    },

    activateAllocation({ commit, dispatch, state }, params) {
        return new Promise((resolve, reject) => {
            axios.patch(`/api/products/${state.product.id}/`, { requires_allocation: true })
                .then((response) => {
                    resolve(response);
                    dispatch('fetchProduct');
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    reject(error);
                });
        });
    },

    saveProductProviders({ commit, dispatch, state }, params) {
        commit('updateProductProvidersSaving', true);
        commit('updateProductProvidersSavingErrors', null);

        return new Promise((resolve, reject) => {
            axios.patch(`/api/products/${state.product.id}/`, { providers: state.productproviders })
                .then((response) => {
                    commit('updateProductProvidersSaving', false);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductProvidersSavingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateProductProvidersSaving', false);
                });
        });
    },

    async activateStockTracking({ commit, dispatch, state }, params) {
        try {
            const response = await axios.patch(`/api/products/${state.product.id}/`, { stock_tracking: true });
            commit('updateProduct', response.data);
            return response;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        }
    },

    async init({ commit, dispatch, state }, params) {
        commit('updateProductStats', {});
        commit('updateSection', params.section || null);
        dispatch('allocations/init', {
            filters: { product: params.product.id },
        });
        dispatch('customerorderitems/init', {
            filters: { product: params.product.id, }, type: 'customerorder',
        });
        dispatch('providerorderitems/init', {
            filters: { product: params.product.id, suborder: false }, type: 'providerorder',
        });
        dispatch('shipmentitems/init', {
            filters: { product: params.product.id },
        });
        dispatch('receiptitems/init', {
            filters: { product: params.product.id },
        });
        dispatch('providers/init', {
            filters: { connected: false, limit: 1000, ordering: 'name' },
        });
        commit('updateProduct', params.product);
        dispatch('fetchProductStats');
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        allocations: allocations(),
        customerorderitems: orderitems(),
        providerorderitems: orderitems(),
        shipmentitems: shipmentitems(),
        receiptitems: receiptitems(),
        providers: providers(),
    },
};

