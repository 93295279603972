import axios from '@/plugins/axios';
import qs from 'qs';
import utils from '@/stores/utils';

const createState = () => ({
    permissions: [],
    permissions_filters: { offset: 0, limit: 1000, },
    permissions_count: 0,
    permissions_loaded: false,
    permissions_loading: false,
    permissions_loading_errors: null,

    permission_deleting: false,
    permission_deleting_errors: null,

    profile: null,
});

const getters = {
    apps(state) {
        const uniqueApps = new Set();
        state.permissions.forEach(item => {
            uniqueApps.add(item.app);
        });
        return [...uniqueApps];
    },
    getModelsByApp: (state) => (app) => {
        const models = new Set();
        state.permissions.forEach(item => {
            if (item.app === app) {
                models.add(item.model);
            }
        });
        return Array.from(models);
    },
    getItemsByAppOrdered: (state) => (app) => {
        return state.permissions
            .filter(item => item.app === app)
            .sort((a, b) => {
                // Trie par model, puis par name
                if (a.model === b.model) {
                    return a.name.localeCompare(b.name);
                }
                return a.model.localeCompare(b.model);
            });
    },
}


const mutations = {
    updatePermissions(state, permissions) {
        state.permissions = permissions;
        state.permissions_loaded = true;
    },
    updatePermissionsCount(state, count) {
        state.permissions_count = count;
    },
    updatePermissionsFilters(state, filters) {
        state.permissions_filters = filters;
    },
    updatePermissionsLoading(state, loading) {
        state.permissions_loading = loading;
    },
    updatePermissionsLoadingErrors(state, errors) {
        state.permissions_loading_errors = errors;
    },

    updatePermissionDeleting(state, deleting) {
        state.permission_deleting = deleting;
    },
    updatePermissionDeletingErrors(state, errors) {
        state.permission_deleting_errors = errors;
    },
    updatePermissionsLoaded(state, loaded) {
        state.permissions_loaded = loaded;
    },
    updateProfile(state, profile) {
        state.profile = profile;
        state.permissions_filters.profile = profile;
    }
};

const actions = {

    exportPermissionsAsCSV({ commit, dispatch, state }, params) {
        let filters = {};
        if (params.filtered) {
            filters = Object.assign({}, state.permissions_filters);
            filters.offset = null;
        }
        filters.format = 'csv';
        filters.limit = 10000;
        const queryparams = qs.stringify(filters, { arrayFormat: 'repeat' });
        window.open("/api/permissions/?" + queryparams);
    },

    async deletePermission({ commit, dispatch, state }, params) {
        const url = `/api/permissions/${params.instance.id}/`;

        commit('updatePermissionDeleting', true);
        commit('updatePermissionDeletingErrors', null);

        try {
            const response = await axios.delete(url);
            commit('updatePermissionDeleting', false);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updatePermissionDeleting', false);
            commit('updatePermissionDeletingErrors', error.details);
            throw error;
        }
    },

    async fetchPermissions({ commit, dispatch, state }, params) {
        commit('updatePermissionsLoading', true);
        commit('updatePermissionsLoadingErrors', null);

        try {
            const response = await axios.get('/api/permissions/', { params: state.permissions_filters });
            commit('updatePermissionsLoading', false);
            commit('updatePermissions', response.data.results);
            commit('updatePermissionsCount', response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updatePermissionsLoadingErrors', error.details);
            commit('updatePermissionsLoading', false);
            throw error;
        }
    },

    init({ commit, dispatch, state }, params) {
        commit('updateProfile', params?.profile);
        commit('updatePermissionsLoaded', false);
        commit('updatePermissions', []);
        commit('updatePermissionsCount', 0);
    }
};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    getters,
    actions
});