import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const createState = () => ({
    shipments: [],
    shipments_stats: {},
    shipments_stats_loading: false,
    shipments_filters: { offset: 0, limit: 20, },
    shipments_count: 0,
    shipments_loaded: false,
    shipments_loading: false,
    shipments_loading_errors: null,
    fetch_stats: true,
});

const mutations = {
    updateShipments(state, shipments) {
        state.shipments = shipments;
        state.shipments_loaded = true;
        state.shipments_loading = false;
        state.shipments_loading_errors = null;
    },
    updateShipmentsFilters(state, shipments_filters) {
        state.shipments_filters = shipments_filters;
    },
    updateShipmentsLoading(state, shipments_loading) {
        state.shipments_loading = shipments_loading;
    },
    updateShipmentsLoadingErrors(state, shipments_loading_errors) {
        state.shipments_loading_errors = shipments_loading_errors;
        state.shipments_loading = false;
    },
    updateShipmentsCount(state, shipments_count) {
        state.shipments_count = shipments_count;
    },
    updateShipmentsStats(state, shipments_stats) {
        state.shipments_stats = shipments_stats;
    },
    updateFetchStats(state, fetch_stats) {
        state.fetch_stats = fetch_stats;
    },
    updateShipmentsStatsLoading(state, shipments_stats_loading) {
        state.shipments_stats_loading = shipments_stats_loading;
    },
};

const actions = {

    async fetchShipments({ commit, dispatch, state }) {
        if (store.getters['session/current_user_permissions'].indexOf("core.view_shipment") == -1) {
            return;
        }

        commit('updateShipmentsLoading', true);
        commit('updateShipmentsLoadingErrors', null);

        try {
            const response = await axios.get("/api/shipments/", { params: state.shipments_filters });
            commit('updateShipments', response.data.results);
            commit('updateShipmentsCount', response.data.count);
            await dispatch('fetchShipmentsStats');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateShipmentsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateShipmentsLoading', false);
        }
    },


    async fetchShipmentsStats({ commit, state }) {
        if (!state.fetch_stats) {
            return;
        }
        try {
            commit('updateShipmentsStatsLoading', true);
            const response = await axios.get("/api/shipments/stats/", { params: state.shipments_filters });
            commit('updateShipmentsStats', response.data);
            return response.data;
        } catch (xhr_error) {
            throw utils.handleError(xhr_error);
        } finally {
            commit('updateShipmentsStatsLoading', false);
        }
    },


    async init({ commit, dispatch, state }, params) {
        commit('updateShipments', []);
        const default_filters = { offset: 0, limit: 20 };
        await commit('updateFetchStats', params.fetch_stats === undefined ? true : params.fetch_stats);
        await commit('updateShipmentsFilters', { ...default_filters, ...(params.filters || {}) });
    },
};


export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions
});