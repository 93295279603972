import Vue from 'vue';
import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from './store';

const state = {
    deliverysite: null,
    deliverysite_saving: false,
    deliverysite_errors: {},
};

const mutations = {
    updateDeliverySite(state, deliverysite) {
        state.deliverysite = Object.assign({ entity_data: {}, entity: null }, JSON.parse(JSON.stringify(deliverysite)));
        if (!state.deliverysite.address) {
            state.deliverysite.address = {};
        }
        state.deliverysite_errors = {};
    },

    updateDeliverySiteErrors(state, errors) {
        state.deliverysite_errors = errors;
    },
    updateDeliverySiteSaving(state, saving) {
        state.deliverysite_saving = saving;
    },
};

const actions = {
    async saveDeliverySite({ commit, dispatch }, params) {
        commit('updateDeliverySiteSaving', true);
        let url;
        let method = axios.post;

        if (params.instance.customer) {
            url = `/api/customers/${params.instance.customer}/deliverysites/`;
        } else if (params.instance.entity && params.instance.entity != store.getters['session/current_entity_id']) {
            throw new Error('Delivery site cannot be edited');
        } else {
            url = `/api/deliverysites/`;
        }

        if (params.instance.id) {
            url += `${params.instance.id}/`;
            method = axios.put;
        }

        try {
            const response = await method(url, params.instance);
            return response;
        } catch (xhr_error) {
            let error = utils.handleError(xhr_error);
            commit('updateDeliverySiteErrors', error.details);
            throw error;
        } finally {
            dispatch('session/fetchStats', null, { root: true });
            commit('updateDeliverySiteSaving', false);
        }
    },

    async init({ commit, dispatch, state }, params) {
        let deliverysite = { ...(params?.deliverysite || {}) };
        if (!deliverysite.id) {
            if (params.deliverysite.customer) {
                deliverysite.address = params.deliverysite.customer_data?.billing_address;
            } else {
                deliverysite.address = store.getters['session/current_entity'].billing_address;
                if (store.getters['session/stats'].deliverysites === 0) {
                    deliverysite.name = Vue.prototype.$gettext('Main address');
                }
            }
        }
        await commit('updateDeliverySite', deliverysite);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
