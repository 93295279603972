var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "drop-zone",
    "class": {
      dragging: _vm.isDragging
    },
    on: {
      "dragover": function dragover($event) {
        $event.preventDefault();
        return _vm.handleDragOver.apply(null, arguments);
      },
      "dragenter": function dragenter($event) {
        $event.preventDefault();
        return _vm.handleDragEnter.apply(null, arguments);
      },
      "dragleave": _vm.handleDragLeave,
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.handleDrop.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_vm._t("default")], 2), _vm.isDragging ? _c('div', {
    staticClass: "overlay"
  }, [_c('p', [_vm._v(_vm._s(_vm.dragText || _vm.$gettext("Drop your files here")))])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }