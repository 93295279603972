<template>
    <div class="fileselect">
        <input
            ref="fileinput"
            type="file"
            :accept="accept"
            @input="handleFileChange"
            @change="handleFileChange"
            :key="trynumber"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: File,
        buttonClass: [String, Array, Object],
        size: String,
        role: String,
        text: String,
        accept: String,
    },
    data() {
        return {
            trynumber: 0,
        };
    },
    methods: {
        chooseFile() {
            this.$refs.fileinput.value = null; // Réinitialiser la valeur avant d'ouvrir le sélecteur
            this.$refs.fileinput.click();
        },
        handleFileChange(e) {
            const file = e.target.files[0];
            if (file) {
                this.$emit("input", file);
                this.trynumber += 1;

                // Réinitialisation après traitement
                this.$refs.fileinput.value = null;
            }
        },
    },
};
</script>

<style lang="scss">
/* Don't forget to hide the original file input! */
.fileselect > input[type="file"] {
    display: none;
}
</style>
