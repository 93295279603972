import axios from "@/plugins/axios";
import qs from "qs";
import utils from "@/stores/utils";

const createState = () => ({
    invitations: [],
    invitations_filters: { offset: 0, limit: 20 },
    invitations_count: 0,
    invitations_loaded: false,
    invitations_loading: false,
    invitations_loading_errors: null,

    invitation_deleting: false,
    invitation_deleting_errors: null,

    invitation_saving: false,
    invitation_saving_errors: null,
});

const mutations = {
    updateInvitations(state, invitations) {
        state.invitations = invitations;
        state.invitations_loaded = true;
    },
    updateInvitationsCount(state, count) {
        state.invitations_count = count;
    },
    updateInvitationsFilters(state, filters) {
        state.invitations_filters = filters;
    },
    updateInvitationsLoading(state, loading) {
        state.invitations_loading = loading;
    },
    updateInvitationsLoadingErrors(state, errors) {
        state.invitations_loading_errors = errors;
    },

    updateInvitationDeleting(state, deleting) {
        state.invitation_deleting = deleting;
    },
    updateInvitationDeletingErrors(state, errors) {
        state.invitation_deleting_errors = errors;
    },
    updateInvitationsLoaded(state, loaded) {
        state.invitations_loaded = loaded;
    },

    updateInvitationSaving(state, saving) {
        state.invitation_saving = saving;
    },
    updateInvitationSavingErrors(state, errors) {
        state.invitation_saving_errors = errors;
    },
};

const actions = {
    async deleteInvitation({ commit, dispatch, state }, params) {

        const url = `/api/invitations/${params.instance.uuid}/`;

        commit("updateInvitationDeleting", true);
        commit("updateInvitationDeletingErrors", null);

        try {
            const response = await axios.delete(url);
            commit("updateInvitationDeleting", false);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateInvitationDeleting", false);
            commit("updateInvitationDeletingErrors", error.details);
            throw error;
        }
    },

    async fetchInvitations({ commit, dispatch, state, rootGetters }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_invitation") === -1) {
            return;
        }
        commit("updateInvitationsLoading", true);
        commit("updateInvitationsLoadingErrors", null);

        try {
            const response = await axios.get("/api/invitations/", {
                params: state.invitations_filters,
            });
            commit("updateInvitationsLoading", false);
            commit("updateInvitations", response.data.results);
            commit("updateInvitationsCount", response.data.count);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateInvitationsLoadingErrors", error.details);
            commit("updateInvitationsLoading", false);
            throw error;
        }
    },

    async saveInvitation({ commit, dispatch, state, rootGetters }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.add_invitation") === -1) {
            return;
        }

        let url = "/api/invitations/";
        let method = axios.post;

        commit("updateInvitationSaving", true);
        commit("updateInvitationSavingErrors", null);

        try {
            const response = await method(url, params.data);
            return response;
        } catch (xhr_error) {
            let error = utils.handleError(xhr_error);
            commit("updateInvitationSavingErrors", error.details);
            throw error;
        } finally {
            commit("updateInvitationSaving", false);
        }
    },

    init({ commit, dispatch, state }, params) {

        commit("updateInvitationsLoaded", false);
        commit("updateInvitations", []);
        commit("updateInvitationsCount", 0);
        commit("updateInvitationsFilters", {
            ...{ offset: 0, limit: 20 },
            ...(params?.filters || {}),
        });
    },
};

export default () => ({
    namespaced: true,
    state: createState(),
    mutations,
    actions,
});
