var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.modals, function (modal, idx) {
    return _c('v-dialog', {
      key: modal.id,
      staticClass: "d-flex",
      attrs: {
        "value": idx == _vm.modals.length - 1,
        "persistent": modal.persistent === undefined ? true : modal.persistent,
        "max-width": _vm.getMaxWidth(modal),
        "width": _vm.getWidth(modal),
        "min-height": "100%",
        "height": "1000px"
      }
    }, [_c('v-card', {
      staticClass: "pa-0 d-flex flex-column"
    }, [_c(modal.component, {
      tag: "component",
      staticClass: "w-100 overflow-hidden",
      attrs: {
        "params": modal.params
      }
    })], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }