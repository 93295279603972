import Vue from 'vue';
import axios from '@/plugins/axios';
import storeUtils from '@/stores/utils';
import sendinvoice from "./submodules/sendinvoice";
import store from '@/stores/store';

const state = {
    billingaccount: null,
    billed_email_available: false,
    billed: null,
    biller: null,
    invoice: null,
    subject: null,
    sending_invoice: false,
    sending_invoice_errors: {},
};

function make_subject(invoice) {
    let subject = Vue.prototype.$gettext('Invoice {biller} n°{invoice_id}');
    subject = subject.replace('{total_incl_vat}', invoice.total_incl_vat);
    subject = subject.replace('{biller}', invoice.biller_data.name);
    subject = subject.replace('{invoice_id}', invoice.invoice_id);
    return subject;
}

const mutations = {

    updateInvoice(state, invoice) {
        state.invoice = invoice;
        state.billed = invoice?.billed_data;
        state.biller = invoice?.biller_data;
        state.subject = invoice ? make_subject(invoice) : null;
        state.sending_invoice = false;
        state.sending_invoice_errors = {};
    },

    updateSendingInvoice(state, sending) {
        state.sending_invoice = sending;
        state.sending_invoice_errors = {};
    },
    updateSendingInvoiceErrors(state, sending_invoice_errors) {
        state.sending_invoice_errors = sending_invoice_errors;
        state.sending_invoice = false;
    },

    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
    },

    updateBilled(state, billed) {
        state.billed = billed;
        state.billed_email_available = !!billed?.billing_address?.email;
    },

};


const actions = {

    sendInvoice({ commit, dispatch, state }, params) {
        let sendparams = {
            receive_copy: store.state.sendinvoice.sendinvoice['receive_copy'],
            recipients: store.state.sendinvoice.sendinvoice['recipients'],
            subject: store.state.sendinvoice.sendinvoice['subject'],
            message: store.state.sendinvoice.sendinvoice['message'],
            save_message: store.state.sendinvoice.sendinvoice['save_message'],
        };

        return new Promise((resolve, reject,) => {
            commit('updateSendingInvoice', true);
            commit('updateSendingInvoiceErrors', {});
            commit('sendinvoice/updateSendingInvoice', true);
            commit('sendinvoice/updateSendingInvoiceErrors', {});

            axios.post(`/api/customerinvoices/${state.invoice.id}/send/`, sendparams)
                .then((response) => {
                    resolve(response.data);
                    dispatch('session/fetchStats', null, { root: true });
                    dispatch('billingstats/fetchAllStats', null, { root: true });
                })
                .catch((xhr_error) => {
                    const error = storeUtils.handleError(xhr_error);
                    commit('updateSendingInvoiceErrors', error.details);
                    commit('sendinvoice/updateSendingInvoiceErrors', error.details);
                    reject(error);
                }).finally(() => {
                    commit('updateSendingInvoice', false);
                    commit('sendinvoice/updateSendingInvoice', false);
                });
        });
    },


    fetchBillingAccount({ commit, dispatch, state }, params) {
        return new Promise((resolve, reject,) => {
            axios.get("/api/billingaccounts/", { params: { customer: state.billed.id } })
                .then((response) => {
                    if (response.data.results?.length) {
                        let billingaccount = response.data.results[0];

                        commit('updateBillingAccount', billingaccount);
                        commit('sendinvoice/updateMessage', billingaccount.invoice_notification_text || Vue.prototype.$gettext('Hello,\n\nPlease find attached invoice #{invoice_id}.\n\nBest regards.').replace('{invoice_id}', state.invoice.invoice_id));
                    } else {
                        state.message = null;
                    }
                })
                .catch((xhr_error) => {
                    const error = storeUtils.handleError(xhr_error);
                    reject(error);
                })
        });
    },

    async init({ commit, dispatch, state }, params) {
        await commit('updateInvoice', params?.invoice);
        commit('updateBilled', params?.invoice?.billed_data);
        commit('updateSendingInvoiceErrors', {});
        let email = params.invoice.billed_data.billing_address.email;

        dispatch('sendinvoice/init', {
            recipients: email ? [params.invoice.billed_data.billing_address.email] : [],
            subject: state.subject,
        });


        if (params?.invoice) {
            dispatch('fetchBillingAccount');
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        sendinvoice: sendinvoice(),
    }
};
