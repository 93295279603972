<template>
    <div>
        <v-dialog
            :value="idx == modals.length - 1"
            :persistent="modal.persistent === undefined ? true : modal.persistent"
            :max-width="getMaxWidth(modal)"
            class="d-flex"
            v-for="(modal, idx) in modals"
            :key="modal.id"
            :width="getWidth(modal)"
            min-height="100%"
            height="1000px"
        >
            <v-card class="pa-0 d-flex flex-column">
                <component
                    class="w-100 overflow-hidden"
                    :is="modal.component"
                    :params="modal.params"
                >
                </component>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { max } from "vue-moment";
import { mapState, mapActions } from "vuex";

export default {
    props: {
        maxWidth: {
            type: [Number, String],
            default: "90%",
        },
        maxHeight: {
            type: [Number, String],
            default: "90%",
        },
        width: {
            type: [Number, String],
            default: 890,
        },
        height: {
            type: [Number, String],
            default: "90%",
        },
    },
    computed: Object.assign(
        mapState("mcl", {
            currentModal: "modal",
            modals: "modals",
        }),
        {}
    ),
    methods: Object.assign(mapActions("mcl", ["hideModal"]), {
        clickedOutside() {
            this.hideModal();
        },
        getMaxWidth(modal) {
            return modal?.component?.maxWidth || this.maxWidth;
        },
        getMaxHeight(modal) {
            return modal?.component?.maxHeight || this.maxHeight;
        },
        getWidth(modal) {
            return modal?.component?.width || this.width;
        },
        getHeight(modal) {
            return modal?.component?.height || this.height;
        },
    }),
};
</script>
