import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import billingaccounts from './submodules/billingaccounts';
import catalogs from './submodules/catalogs';
import stocks from './submodules/stocks';

const state = {
    customer: null,
    customer_saving: false,
    customer_saving_errors: {},

    billingaccount: null,
    billingaccount_loading: false,

    allowed_stocks: [],
    allowed_catalogs: [],
};

const mutations = {
    updateCustomer(state, customer) {
        state.customer = customer;
    },
    updateCustomerSaving(state, saving) {
        state.customer_saving = saving;
    },
    updateCustomerSavingErrors(state, errors) {
        state.customer_saving_errors = errors;
    },
    updateCustomerBillingAccountId(state, billingaccount) {
        state.customer.billingaccount = billingaccount;
    },
    updateCustomerBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
    },
    updateBillingAccountLoading(state, loading) {
        state.billingaccount_loading = loading;
    },
    setDefaultStock(state, stocks) {
        if (!state.customer?.id) {
            const main_stock = stocks.find((item) => item.main)
            if (main_stock) {
                state.allowed_stocks = [main_stock.id];
            } else {
                state.allowed_stocks = [];
            }
        } else {
            state.allowed_stocks = state.stocks.stocks.filter((item) => item.allowed).map((item) => item.id);
        }
    },
    setDefaultCatalog(state, catalogs) {

        if (!state.customer.id) {
            const maincatalog = catalogs.find((item) => item.default);
            if (maincatalog) {
                state.allowed_catalogs = [maincatalog.id];
            } else {
                state.allowed_catalogs = [];
            }
        } else {
            state.allowed_catalogs = state.catalogs.catalogs.filter((item) => item.allowed).map((item) => item.id);
        }
    },
    updateAllowedStocks(state, stocks) {
        state.allowed_stocks = stocks;
    },
    updateAllowedCatalogs(state, catalogs) {
        state.allowed_catalogs = catalogs;
    },
};

const actions = {

    async saveCustomer({ commit, dispatch, state }) {
        commit('updateCustomerSaving', true);
        let url = '/api/customers/';
        let method = axios.post;
        if (state.customer.id) {
            url = `/api/customers/${state.customer.id}/`;
            method = axios.put;
        }
        let customer = { ...state.customer, catalogs: state.allowed_catalogs, allowed_stocks: state.allowed_stocks };

        try {
            const response = await method(url, customer);
            commit('updateCustomerSaving', false);
            dispatch('session/fetchStats', null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateCustomerSavingErrors', error.details);
            commit('updateCustomerSaving', false);
            throw error;
        }
    },
    async fetchBillingAccount({ commit, dispatch, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("billing.view_billingaccount") === -1) {
            return;
        }
        commit('updateBillingAccountLoading', true);
        try {
            const response = await dispatch('activebillingaccounts/fetchBillingAccounts');
            if (response.results.length > 0) {
                commit('updateCustomerBillingAccountId', response.results[0].id);
                commit('updateCustomerBillingAccount', response.results[0]);
            }
        } finally {
            commit('updateBillingAccountLoading', false);
        }
    },

    changeBillingAccountId({ state, commit }, billingaccount_id) {
        commit('updateCustomerBillingAccountId', billingaccount_id);
        commit('updateBillingAccount', state.billingaccounts.billingaccounts.find((item) => item.id == billingaccount_id));
    },

    async init({ commit, dispatch, state }, params) {
        await commit('updateCustomerSavingErrors', {});
        await commit('updateCustomer', params.customer);
        await dispatch('billingaccounts/fetchBillingAccounts');
        await dispatch('fetchBillingAccount');
        await dispatch('activebillingaccounts/init', {
            filters: { customer: state.customer.id }
        });

        await dispatch('stocks/init', {
            filters: { customerstock_customer: state.customer.id }
        });
        let stocks = await dispatch('stocks/fetchStocks')
        commit('setDefaultStock', stocks?.results);


        await dispatch('catalogs/init', {
            filters: { limit: 1000, assignment_customer: state.customer.id, ordering: 'name' }
        });
        let catalogs = await dispatch('catalogs/fetchCatalogs');
        commit('setDefaultCatalog', catalogs?.results);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        catalogs: catalogs(),
        stocks: stocks(),
        billingaccounts: billingaccounts(),
        activebillingaccounts: billingaccounts(),
    }
};