import axios from '@/plugins/axios';
import utils from '@/stores/utils';

const state = {
    billingaccount: false,
    billingaccount_changed: false,
    billingaccount_saving: false,
    billingaccount_saving_errors: {},
    dashboard_logo_uploading: false,
    documents_logo_uploading: false,
};


const mutations = {

    updateDashboardLogo(state, dashboard_logo) {
        state.billingaccount.dashboard_logo = dashboard_logo;
    },

    updateBillingAccount(state, billingaccount) {
        state.billingaccount = billingaccount;
        state.billingaccount_changed = false;
        state.billingaccount_fetching = false;
        state.billingaccount_saving_errors = {};
        if (state.billingaccount != null && !state.billingaccount.address) {
            state.billingaccount.address = {};
        }
    },

    updateBillingAccountSaving(state, saving) {
        state.billingaccount_saving = saving;
        state.billingaccount_saving_errors = {};
    },

    updateBillingAccountFetching(state, fetching) {
        state.billingaccount_fetching = fetching;
    },

    updateBillingAccountSavingErrors(state, saving_errors) {
        state.billingaccount_saving_errors = saving_errors || {};
        state.billingaccount_saving = false;
    },

    changeBillingaccount(state, billingaccount) {
        state.billingaccount = Object.assign({}, state.billingaccount, billingaccount);
        state.billingaccount_changed = true;
    },

    updateDashboardLogoUploading(state, uploading) {
        state.dashboard_logo_uploading = uploading;
    },

    updateDocumentsLogoUploading(state, uploading) {
        state.documents_logo_uploading = uploading;
    },

    updateDocumentsLogo(state, documents_logo) {
        state.billingaccount.documents_logo = documents_logo;
    },
};

const actions = {

    async saveBillingAccount({ commit, dispatch, state }, params) {
        commit('updateBillingAccountSaving', true);
        let url = "/api/billingaccounts/";
        let method = axios.post;

        if (state.billingaccount.id) {
            url = `/api/billingaccounts/${state.billingaccount.id}/`;
            method = axios.patch;
        }

        let billingaccount = { ...state.billingaccount };
        if (billingaccount.dashboard_logo) {
            delete billingaccount.dashboard_logo;
        }
        if (billingaccount.documents_logo) {
            delete billingaccount.documents_logo;
        }
        try {
            const response = await method(url, billingaccount);
            commit('updateBillingAccount', { ...state.billingaccount, id: response.data.id });
            commit('updateBillingAccountSaving', false);
            dispatch('billingaccounts/fetchBillingAccounts', null, { root: true });
            dispatch('session/fetchLoginInfo', null, { root: true });

            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateBillingAccountSavingErrors', error.details);
            throw error;
        }
    },





    async fetchBillingAccount({ commit, dispatch, state }, params) {
        commit('updateBillingAccountFetching', true);

        let url;
        if (params && params.billingaccount_id) {
            url = `/api/billingaccounts/${params.billingaccount_id}/`;
        } else if (state.billingaccount) {
            url = `/api/billingaccounts/${state.billingaccount.id}/`;
        } else {
            throw "no billingaccount to fetch";
        }

        try {
            const response = await axios.get(url);
            commit('updateBillingAccount', response.data);
            await dispatch('session/fetchLoginInfo', null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateBillingAccountFetching', false);
            throw error;
        }
    },

    async deleteBillingAccount({ commit, dispatch, state }, params) {
        let url = `/api/billingaccounts/${state.billingaccount.id}/`;
        try {
            const response = await axios.delete(url);
            commit('updateBillingAccount', null);
            await dispatch('session/fetchLoginInfo', null, { root: true });
            await dispatch('billingaccounts/fetchBillingAccounts', null, { root: true });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },


    async uploadDashboardLogo({ commit, dispatch, state }, file) {
        commit('updateDashboardLogoUploading', true);
        try {
            let url = `/api/billingaccounts/${state.billingaccount.id}/`;

            let data = {};
            let options = {};
            if (file) {
                data = new FormData();
                data.append('dashboard_logo', file);
                options.headers = { 'Content-Type': 'multipart/form-data' };
            } else {
                data.dashboard_logo = null;
            }
            let response = await axios.patch(url, data, options);
            commit('updateDashboardLogoUploading', false);
            commit('updateDashboardLogo', response.data.dashboard_logo);
            dispatch('session/fetchLoginInfo', null, { root: true });
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateDashboardLogoUploading', false);
            throw error;
        }
    },


    async uploadDocumentsLogo({ commit, dispatch, state }, file) {
        commit('updateDocumentsLogoUploading', true);
        try {
            let url = `/api/billingaccounts/${state.billingaccount.id}/`;

            let data = {};
            let options = {};
            if (file) {
                data = new FormData();
                data.append('documents_logo', file);
                options.headers = { 'Content-Type': 'multipart/form-data' };
            } else {
                data.documents_logo = null;
            }

            let response = await axios.patch(url, data, options);
            commit('updateDocumentsLogoUploading', false);
            commit('updateDocumentsLogo', response.data.documents_logo);
            dispatch('session/fetchLoginInfo', null, { root: true });
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateDocumentsLogoUploading', false);
            throw error;
        }
    },

    async init({ commit, dispatch, state }, params) {
        if (!params.billingaccount?.id) {
            commit('updateBillingAccount', { handle_invoices: false });
        } else if (params.billingaccount) {
            commit('updateBillingAccount', params.billingaccount);
        }
    }

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
