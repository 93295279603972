import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import synchronize from './submodules/synchronize';
import store from '@/stores/store';


const state = {
    producttypes: [],
    producttypes_loading: false,
    producttypes_loaded: false,
    producttypes_loading_errors: null,
};


const mutations = {
    updateProductTypes(state, producttypes) {
        state.producttypes = producttypes;
        state.producttypes_loaded = true;
        state.producttypes_loading = false;
    },
    updateProductTypesLoading(state, loading) {
        state.producttypes_loading = loading;
        if (loading) {
            state.producttypes_loading_errors = null;
            state.producttypes_loaded = false;
        }
    },
    updateProductTypesLoadingErrors(state, errors) {
        state.producttypes_loading_errors = errors;
        state.producttypes_loading = false;
        state.producttypes_loaded = true;
    },
};

const actions = {
    async init({ commit, dispatch, state }, params) {
        await dispatch("synchronize/init");
        await dispatch("fetchProductTypes");
    },


    fetchProductTypes({ commit, dispatch, state }, params) {
        commit('updateProductTypesLoading', true);

        return new Promise((resolve, reject) => {

            axios.get('/api/producttypes/', { params: { limit: 500 } })
                .then((response) => {
                    commit('updateProductTypes', response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit('updateProductTypesLoadingErrors', error.details);
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,

    actions,
    mutations,
    modules: {
        synchronize: synchronize(),
    }
};
