import qs from "qs";
import axios from "@/plugins/axios";
import utils from "@/stores/utils";
import store from "@/stores/store";
import orders from "@/stores/submodules/orders";

const state = {
    invoice: null,
    invoice_saving: false,
    invoice_saving_errors: {},

    providers: [],
    providers_loading: false,
    providers_loading_errors: null,

    parsing_invoice: false,
    parsing_invoice_errors: null,
    parsing_mismatch: false,

    remove_invoice_file: false,
    invoice_file: null,
    invoice_file_url: null,
    invoice_data: null,
};

const mutations = {
    initInvoice(state, invoice) {
        state.invoice = invoice;
        state.invoice_data = null;
        state.invoice_saving = false;
        state.invoice_saving_errors = {};
        state.invoice_file = null;
        state.invoice_file_url = invoice?.invoice_file;
        state.remove_invoice_file = false;
        state.parsing_mismatch = false;
        state.parsing_invoice = false;
        state.parsing_invoice_errors = null;
        state.providers_loading = false;
        state.providers_loading_errors = null;

    },

    updateInvoice(state, invoice) {
        state.invoice = invoice;
    },

    updateInvoiceSaving(state, invoice_saving) {
        state.invoice_saving = invoice_saving;
    },
    updateInvoiceSavingErrors(state, invoice_saving_errors) {
        state.invoice_saving_errors = invoice_saving_errors;
    },

    updateProviders(state, providers) {
        state.providers = providers;
        state.providers_loading = false;
        state.providers_loading_errors = null;
    },
    updateProvidersLoading(state, loading) {
        state.providers_loading = loading;
        state.providers_loading_errors = null;
    },
    updateProvidersLoadingErrors(state, errors) {
        state.providers_loading = false;
        state.providers_loading_errors = errors;
    },
    updateInvoiceFile(state, invoice_file) {
        if (invoice_file) {
            state.invoice_file = invoice_file;
            state.invoice_file_url = URL.createObjectURL(invoice_file);
        } else {
            if (state.invoice_file_url) {
                state.remove_invoice_file = true;
            }
            state.invoice_file = null;
            state.invoice_file_url = null;
        }
    },
    updateParsingInvoice(state, parsing_invoice) {
        state.parsing_invoice = parsing_invoice;
    },

    updateParsingInvoiceErrors(state, parsing_invoice_errors) {
        state.parsing_invoice_errors = parsing_invoice_errors;
    },

    async forceupdateInvoiceWithParsedData(state) {

        if (state.invoice_data.invoice_id) {
            state.invoice.invoice_id = state.invoice_data.invoice_id;
        }
        if (state.invoice_data.invoice_date) {
            state.invoice.invoice_date = state.invoice_data.invoice_date;
        }
        if (state.invoice_data.due_date) {
            state.invoice.due_date = state.invoice_data.due_date;
        }
        if (state.invoice_data.total_excl_vat) {
            state.invoice.total_excl_vat = state.invoice_data.total_excl_vat;
        }
        if (state.invoice_data.total_incl_vat) {
            state.invoice.total_incl_vat = state.invoice_data.total_incl_vat;
        }
        if (state.invoice_data.total_vat) {
            state.invoice.total_vat = state.invoice_data.total_vat;
        }
    },

    async updateInvoiceWithParsedData(state, invoice_data) {
        state.invoice_data = invoice_data;
        state.parsing_mismatch = false;

        for (let key of ['total_excl_vat', 'total_incl_vat', 'total_vat', 'invoice_id', 'invoice_date', 'due_date']) {
            if (invoice_data[key] && state.invoice[key]) {
                if (invoice_data[key] != state.invoice[key]) {
                    state.parsing_mismatch = true;
                    return true;
                }
            }
        }
        for (let key of ['total_excl_vat', 'total_incl_vat', 'total_vat', 'invoice_id', 'invoice_date', 'due_date']) {
            if (invoice_data[key]) {
                state.invoice[key] = invoice_data[key];
            }
        }
    },

};

const actions = {

    fetchProviders({ commit, dispatch, state }, params) {
        commit("updateProvidersLoading", true);

        return new Promise((resolve, reject) => {
            this.request = axios
                .get("/api/providers/", {
                    params: {
                        limit: 1000,
                        ordering: "name",
                        parent: store.getters["session/current_entity_id"],
                    },
                })
                .then((response) => {
                    commit("updateProviders", response.data.results);
                    resolve(response);
                })
                .catch((xhr_error) => {
                    const error = utils.handleError(xhr_error);
                    commit("updateProvidersLoadingErrors", error.details);
                    reject(error);
                });
        });
    },

    async createProvider({ commit, dispatch, state }, params) {
        try {
            const response = await axios.post("/api/providers/", params.provider);
            dispatch("fetchProviders");
            return response.data;
        } catch (xhr_error) {
            return Promise.reject(utils.handleError(xhr_error));
        }
    },


    async uploadInvoiceFile({ commit, dispatch, state }, params) {
        let formData = new FormData();
        formData.append('invoice_file', state.invoice_file);

        try {
            const response = await axios.post(`/api/providerinvoices/${params.invoice.id}/invoicefile/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async removeInvoicefile({ commit, dispatch, state }, params) {
        try {
            const response = await axios.delete(`/api/providerinvoices/${params.invoice.id}/invoicefile/`);
            return response.data;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async saveInvoice({ commit, dispatch, state }, params) {
        commit("updateInvoiceSaving", true);

        let url = "/api/providerinvoices/";
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/providerinvoices/${params.instance.id}/`;
            method = axios.put;
        }

        try {
            const response = await method(url, state.invoice)
            commit("updateInvoiceSaving", false);
            if (state.remove_invoice_file) {
                await dispatch("removeInvoicefile", { invoice: response.data });
            } else if (state.invoice_file) {
                await dispatch("uploadInvoiceFile", { invoice: response.data });
            }
            return response;
        } catch (xhr_error) {
            let error = utils.handleError(xhr_error);
            commit("updateInvoiceSavingErrors", error.details);
            throw error;
        } finally {
            dispatch("session/fetchStats", null, { root: true });
            dispatch("billingstats/fetchAllStats", null, { root: true });
            commit("updateInvoiceSaving", false);
        }
    },

    async parseInvoiceFile({ commit, dispatch, state }, params) {
        commit("updateInvoiceFile", params.invoice_file);
        commit("updateParsingInvoice", true);
        let url = "/api/billing/parseinvoice/";
        try {
            let data = new FormData();
            data.append("invoice_file", params.invoice_file);
            let response = await axios.post(url, data);
            commit("updateParsingInvoice", false);
            await commit("updateInvoiceWithParsedData", response.data);
            return state.parsing_mismatch;

        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit("updateParsingInvoiceErrors", error.details);
            commit("updateParsingInvoice", false);
            throw error;
        }
    },

    async changeProvider({ commit, dispatch, state }, provider) {
        commit("updateInvoice", { ...state.invoice, provider: provider });
        await dispatch("providerorders/init", { url: "/api/providerorders/", filters: { seller: provider } });
        dispatch("providerorders/fetchOrders");
    },

    async changeOrder({ commit, dispatch, state }, order_id) {
        commit("updateInvoice", { ...state.invoice, order: order_id });
        let order = state.providerorders.orders.find((order) => order.id == order_id);
        if (order && !state.invoice.id) {
            const multiply = state.invoice.credit ? -1 : 1;
            commit("updateInvoice", {
                ...state.invoice,
                total_excl_vat: multiply * order.total_price_excl_vat,
                total_incl_vat: multiply * order.total_price_incl_vat,
                total_vat: multiply * order.total_price_vat,
            });
        }
    },

    async init({ commit, dispatch, state }, params) {
        await commit('initInvoice', params.invoice);
        if (params.provider) {
            await dispatch('changeProvider', params.provider);
        }

        if (params.invoice_file) {
            await dispatch('parseInvoiceFile', { invoice_file: params.invoice_file });
        }
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    modules: {
        providerorders: orders(),
    }
};
