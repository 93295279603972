<template>
    <div
        class="drop-zone"
        :class="{ dragging: isDragging }"
        @dragover.prevent="handleDragOver"
        @dragenter.prevent="handleDragEnter"
        @dragleave="handleDragLeave"
        @drop.prevent="handleDrop"
    >
        <div class="content">
            <slot></slot>
        </div>
        <!-- Overlay, shown only during drag -->
        <div v-if="isDragging" class="overlay">
            <p>{{ dragText || $gettext("Drop your files here") }}</p>
        </div>

        <!-- Default content -->
    </div>
</template>

<script>
import Vue from "vue";

export default {
    props: {
        dragText: {
            type: String,
            null: true,
        },
        maxFiles: {
            type: Number,
            default: 5, // Default maximum number of files
        },
    },
    data() {
        return {
            isDragging: false, // Dragging state
            errorMessage: "", // Error message
        };
    },
    methods: {
        handleDragEnter() {
            this.isDragging = true;
        },
        handleDragOver() {
            this.isDragging = true;
        },
        handleDragLeave(event) {
            // Check if the mouse has left the parent zone, not a child
            if (!event.currentTarget.contains(event.relatedTarget)) {
                this.isDragging = false;
            }
        },
        handleDrop(event) {
            this.isDragging = false;
            const files = event.dataTransfer.files; // Retrieve dropped files

            if (files.length > this.maxFiles) {
                // If the limit is exceeded, show an error message
                if (this.maxFiles === 1) {
                    this.errorMessage = this.$gettext("You can only drop one file.");
                } else {
                    this.errorMessage = this.$gettext(
                        "You can only drop up to {maxFiles} files."
                    ).replace("{maxFiles}", this.maxFiles);
                }
                this.$emit("drop-error", this.errorMessage); // Emit an error event
                return;
            }

            this.errorMessage = ""; // Reset error message
            this.$emit("files-dropped", files); // Emit an event with the files
        },
    },
};
</script>

<style scoped>
.drop-zone {
    position: relative;
    width: 100%;
    min-height: 200px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color 0.3s, background-color 0.3s;
}

.drop-zone.dragging {
    border: 2px dashed #ccc; /* Dotted border only during drag */
    background-color: #f8f8f8; /* Slightly different background color */
}

.drop-zone > .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
    background: rgba(255, 255, 255, 0.9); /* White overlay at 50% opacity */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.drop-zone > .overlay > p {
    color: #333;
    font-size: 16px;
    font-weight: bold;
}

.drop-zone > .content {
    position: relative;
    width: 100%;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}
</style>
