import axios from '@/plugins/axios';
import utils from '@/stores/utils';
import store from '@/stores/store';

const state = {
    count: 1,
    shipment: null,
    shipment_id: null,

    customers: [],
    customers_loading: false,
    customers_loading_errors: null,

    deliverysites: [],
    deliverysites_loading: false,
    deliverysites_loading_errors: null,

    shipments: [],
    shipments_loading: false,
    shipments_loading_errors: null,

    shipmentitem: null,
    shipmentitem_saving: false,
    shipmentitem_saving_errors: {},

    stockproduct: null,
    stockproducts_filters: {},

    product: {},
};

const getters = {
    packaging: (state) => {
        return state.shipmentitem.product_data?.packaging || 1;
    },
    maxAvailableCount: (state) => {
        if (!state.stockproduct) {
            return null;
        }
        return state.stockproduct?.count;
    },
    stock: (state) => {
        return state.stockproduct?.stock_data;
    },
};

const mutations = {
    updateCustomers(state, customers) {
        state.customers = customers;
    },
    updateCustomersLoading(state, loading) {
        state.customers_loading = loading;
    },
    updateCustomersLoadingErrors(state, errors) {
        state.customers_loading_errors = errors;
    },

    updateDeliverySites(state, deliverysites) {
        state.deliverysites = deliverysites;
    },
    updateDeliverySitesLoading(state, loading) {
        state.deliverysites_loading = loading;
    },
    updateDeliverySitesLoadingErrors(state, errors) {
        state.deliverysites_loading_errors = errors;
    },
    updateProduct(state, product) {
        state.product = product;
    },
    updateShipments(state, shipments) {
        state.shipments = shipments;
    },
    updateShipmentsLoading(state, loading) {
        state.shipments_loading = loading;
    },
    updateShipmentsLoadingErrors(state, errors) {
        state.shipments_loading_errors = errors;
    },

    updateShipmentItemSaving(state, saving) {
        state.shipmentitem_saving = saving;
    },
    updateShipmentItemSavingErrors(state, errors) {
        state.shipmentitem_saving_errors = errors;
    },
    updateShipment(state, shipment) {
        state.shipment = shipment;
        state.shipment_id = shipment?.id;
    },

    updateShipmentItem(state, shipmentitem) {
        state.shipmentitem = shipmentitem;
        state.shipments_filters = {
            exclude_readonly: true,
            ready: false,
            ordering: "shipment_date",
            limit: 1000,
        }
        state.shipment_id = shipmentitem.shipment;

        state.stockproducts_filters = {
            stock: state.shipmentitem.shipment_data ? state.shipmentitem.shipment_data.stock : null,
            product: state.shipmentitem.product
        };
        state.shipmentitem_saving_errors = [];
    },

    updateStockProduct(state, stockproduct) {
        state.stockproduct = stockproduct;
        if (!state.shipmentitem.id && Number.isFinite(stockproduct?.count)) {
            state.count = Math.min(state.count, (stockproduct?.count - stockproduct?.reserved));
        }
    },

    updateCount(state, count) {
        state.count = count;
        state.shipmentitem_saving_errors = [];
    },

    updateShipmentId(state, shipment_id) {
        state.shipment_id = shipment_id;
        if (!shipment_id) {
            state.shipment = null;
            return;
        }
    },

};



const actions = {

    async fetchCustomers({ commit, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_customer") === -1) {
            return;
        }

        commit('updateCustomersLoading', true);
        commit('updateCustomersLoadingErrors', null);

        try {
            const response = await this.request;
            commit('updateCustomers', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateCustomersLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateCustomersLoading', false);
        }
    },

    async fetchDeliverySites({ commit, rootGetters }, params) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_deliverysite") === -1) {
            return;
        }

        commit('updateDeliverySitesLoading', true);
        commit('updateDeliverySitesLoadingErrors', null);

        const customer_id = params.customer_id;

        try {
            const response = await axios.get(`/api/customers/${customer_id}/deliverysites/`, { params: { limit: 1000 } });
            commit('updateDeliverySites', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateDeliverySitesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateDeliverySitesLoading', false);
        }
    },

    async fetchShipments({ commit, rootGetters }) {
        if (rootGetters['session/current_user_permissions'].indexOf("core.view_shipment") === -1) {
            return;
        }

        commit('updateShipmentsLoading', true);
        commit('updateShipmentsLoadingErrors', null);

        try {
            const response = await axios.get('/api/shipments/', { params: { ready: false, limit: 1000 } });
            commit('updateShipments', response.data.results);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateShipmentsLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateShipmentsLoading', false);
        }
    },

    async fetchShipment({ commit, state, dispatch }) {
        if (!state.shipment_id) {
            return;
        }
        try {
            const response = await axios.get(`/api/shipments/${state.shipment_id}/`);
            await commit('updateShipment', response.data);
            dispatch('fetchStockProduct');
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async fetchStockProduct({ commit, state }) {
        if (!state.shipment) {
            return;
        }
        try {
            const response = await axios.get('/api/stockproducts/', { params: { ...state.stockproducts_filters, stock: state.shipment.stock } });
            commit('updateStockProduct', response.data.results ? response.data.results[0] : {});
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async fetchProduct({ commit, state }) {
        try {
            const response = await axios.get(`/api/products/${state.shipmentitem.product}/`);
            commit('updateProduct', response.data);
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            throw error;
        }
    },

    async saveShipmentItem({ commit, rootGetters, state }) {
        const permission = state.shipmentitem.id ? "core.change_shipmentitem" : "core.add_shipmentitem";
        if (rootGetters['session/current_user_permissions'].indexOf(permission) === -1) {
            return;
        }

        commit('updateShipmentItemSaving', true);
        commit('updateShipmentItemSavingErrors', {});

        let url = '/api/shipmentitems/';
        let method = axios.post;

        if (state.shipmentitem.id) {
            url = `/api/shipmentitems/${state.shipmentitem.id}/`;
            method = axios.put;
        }

        try {
            const response = await method(url, { ...state.shipmentitem, count: state.count, shipment: state.shipment.id });
            return response;
        } catch (xhr_error) {
            const error = utils.handleError(xhr_error);
            commit('updateShipmentItemSavingErrors', error.details);
            throw error;
        } finally {
            commit('updateShipmentItemSaving', false);
        }
    },

    async changeShipment({ commit, dispatch }, shipment) {
        await commit('updateShipment', shipment);
        dispatch('fetchStockProduct');
        commit('updateShipmentItemSavingErrors', null);
    },

    async init({ commit, dispatch, state }, params) {

        const shipmentitem = JSON.parse(
            JSON.stringify({
                count: params.shipmentitem.product_data?.packaging || 1,
                ...(params.shipmentitem || {}),
            })
        );
        await commit('updateShipmentItem', shipmentitem);
        commit('updateCount', shipmentitem.count);
        commit('updateStockProduct', null);
        dispatch('fetchShipment');
        dispatch('fetchShipments');
        dispatch('fetchProduct');
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
