import qs from 'qs';
import axios from '@/plugins/axios';
import storeutils from '@/stores/utils';
import utils from '@/utils';
import store from '@/stores/store';

const state = {
    invoiceitem: null,
    invoiceitem_saving: false,
    invoiceitem_saving_errors: {},
    vat_rate: null,
    step: "search",
    mode: "catalog",
    catalogentry: null,
    billed: null,
    invoice: null,
    invoice_id: null,
    invoices: null,
    invoices_loading: false,
    invoices_loading_errors: null,
};

const mutations = {

    updateInvoiceItem(state, invoiceitem) {
        state.invoiceitem = invoiceitem;
        state.invoice_id = invoiceitem?.invoice;
        state.invoiceitem_saving = false;
        state.invoiceitem_saving_errors = {};

        state.vat_rate = state.invoiceitem.vat_rate !== null ? utils.toPercent(state.invoiceitem.vat_rate) : null;
        if (state.invoiceitem.id) {
            state.step = "configure";
            if (state.invoiceitem.catalogentry) {
                state.catalogentry = state.invoiceitem.catalogentry;
            }
        }
    },

    updateCatalogEntry(state, catalogentry) {
        state.catalogentry = catalogentry;
        state.invoiceitem = {
            invoice: state.invoiceitem.invoice,
            customer: state.params.customer,
            catalogentry: catalogentry.id,
            unit_price_excl_vat: catalogentry.price,
            quantity: 1,
            label: catalogentry.name,
        };
        if (Number.isFinite(state.invoiceitem.vat_rate)) {
            state.vat_rate = utils.toPercent(state.invoiceitem.vat_rate);
        } else {
            state.vat_rate = null;
        }
    },

    updateVatRate(state, vat_rate) {

        state.vat_rate = parseFloat(vat_rate)
        if (!Number.isFinite(state.vat_rate)) {

            state.invoiceitem.vat_rate = null;
        } else {
            state.invoiceitem.vat_rate = vat_rate / 100.0;
        }
    },


    updateInvoiceItemSaving(state, invoiceitem_saving) {
        state.invoiceitem_saving = invoiceitem_saving;
    },

    updateInvoiceItemSavingErrors(state, invoiceitem_saving_errors) {
        state.invoiceitem_saving_errors = invoiceitem_saving_errors;
    },

    updateInvoices(state, invoices) {
        state.invoices = invoices;
    },
    updateInvoicesLoading(state, loading) {
        state.invoices_loading = loading;
    },
    updateInvoicesLoadingErrors(state, errors) {
        state.invoices_loading_errors = errors;
    },
    updateInvoiceId(state, invoice_id) {
        state.invoice_id = invoice_id;
        state.invoiceitem.invoice = invoice_id;
    },
    updateInvoice(state, invoice) {
        state.invoice = invoice;
    },

    updateBilled(state, billed) {
        state.billed = billed;
    },

};

const actions = {

    async fetchInvoices({ commit, dispatch, state }, params) {
        commit('updateInvoices', []);
        commit('updateInvoicesLoading', true);
        try {
            const response = await axios.get('/api/customerinvoices/', { params: { billed: state.billed, status: 'draft', 'ordering': '-invoice_date' } });
            commit('updateInvoices', response.data.results);
            return response.data;
        } catch (xhr_error) {
            const error = storeutils.handleError(xhr_error);
            commit('updateInvoicesLoadingErrors', error.details);
            throw error;
        } finally {
            commit('updateInvoicesLoading', false);
        }
    },

    async fetchInvoice({ commit, dispatch, state }, params) {
        if (!state.invoice_id) {
            return;
        }

        commit('updateInvoice', null);

        try {
            const response = await axios.get(`/api/customerinvoices/${state.invoice_id}/`);
            commit('updateInvoice', response.data);
            return response.data;
        } catch (xhr_error) {
            const error = storeutils.handleError(xhr_error);
            throw error;
        }
    },


    saveInvoiceItem({ commit, dispatch, state }, params) {

        commit('updateInvoiceItemSaving', true);
        let url = '/api/customerinvoiceitems/';
        let method = axios.post;

        if (params.instance.id) {
            url = `/api/customerinvoiceitems/${params.instance.id}/`;
            method = axios.put;
        }

        return new Promise((resolve, reject) => {
            method(url, params.instance)
                .then((response) => {
                    resolve(response);
                })
                .catch((xhr_error) => {
                    let error = storeutils.handleError(xhr_error);
                    commit('updateInvoiceItemSavingErrors', error.details);
                    reject(error);
                })
                .finally(() => {
                    commit('updateInvoiceItemSaving', false);
                    dispatch('billingstats/fetchAllStats', null, { root: true });
                });
        });
    },

    async changeInvoiceId({ commit, dispatch, state }, invoice) {
        await commit('updateInvoiceId', invoice);
        await dispatch('fetchInvoice');
    },

    async init({ commit, dispatch, state }, params) {
        commit('updateInvoiceItem', params.invoiceitem);
        commit('updateBilled', params.billed);
        if (params.invoice) {
            commit('updateInvoice', params.invoice);
        }
        dispatch('fetchInvoices');
        await commit('updateInvoiceId', params.invoiceitem.invoice);
        await dispatch('fetchInvoice');
    },
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
