var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tabsview"
  }, [_c('v-tabs', {
    key: JSON.stringify(_vm.tabs),
    attrs: {
      "color": "primary",
      "value": _vm.tabindex,
      "prev-icon": "mdi-delete-outline"
    }
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('v-tab', {
      key: "".concat(index),
      staticClass: "px-0 mr-4",
      attrs: {
        "disabled": tab.disabled
      },
      on: {
        "change": function change($event) {
          return _vm.$emit('change', tab.name);
        }
      }
    }, [_vm._v(" " + _vm._s(tab.label) + " "), tab.counter ? _c('v-chip', {
      staticClass: "ml-2 justify-center",
      "class": _vm.textColorClass(tab),
      attrs: {
        "small": "",
        "color": _vm.color(tab),
        "outlined": _vm.outlined(tab)
      }
    }, [_vm._v(" " + _vm._s(tab.counter))]) : _vm._e()], 1);
  }), 1), _c('v-tabs-items', {
    attrs: {
      "value": _vm.tabindex,
      "touchless": ""
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('change', _vm.tabs_list[$event]);
      }
    }
  }, [_vm._t("tabs")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }